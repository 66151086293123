import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "cep", "logradouro", "bairro", "cidade", "uf" ]

  search() {
    var cep = this.cepTarget.value.replace(/[^0-9]/g, '')
    if (cep.length != 8) {
      alert('CEP não encontrado')
      return
    }

    var url = 'https://api.postmon.com.br/v1/cep/' + cep
    fetch(url)
      .then(response => response.json())
      .then(json => {
        this.logradouroTarget.value = json.logradouro
        this.bairroTarget.value = json.bairro
        this.cidadeTarget.value = json.cidade
        this.ufTarget.value = json.estado
      })
      .catch(error => {
        this.logradouroTarget.value = ''
        this.bairroTarget.value = ''
        this.cidadeTarget.value = ''
        this.ufTarget.value = ''
        alert('CEP não encontrado')
      })
  }
}
