import { Controller } from "stimulus"
import { plotHistorico } from "./plot"

export default class extends Controller {

  connect() {
    var canvas = this.element.getElementsByTagName('canvas')[0]
    this.plotHistorico(canvas)
  }

  plotHistorico(element) {
    var objetivoColor = 'rgb(54, 162, 235)'
    var realizadoColor = 'rgb(75, 192, 192)'
    var title = element.dataset.title
    var labels = JSON.parse(element.dataset.labels)
    var metas  = JSON.parse(element.dataset.metas)
    var vendas = JSON.parse(element.dataset.vendas)
    var projecoes = JSON.parse(element.dataset.projecoes)
    new Chart(element, {
      "type":"bar",
      "data": {
        "labels": labels,
        "datasets": [
          {
            "label": "Objetivo",
            "stack": "stack-0",
            "data": metas,
            "fill": false,
            "backgroundColor": [
              objetivoColor, objetivoColor, objetivoColor,
              objetivoColor, objetivoColor, objetivoColor,
            ]
          },
          {
            "label":"Realizado",
            "stack": "stack-1",
            "data": vendas,
            "fill": false,
            "backgroundColor": [
              realizadoColor, realizadoColor, realizadoColor,
              realizadoColor, realizadoColor, realizadoColor,
            ]
          },
          {
            "label": "Adicional Projeção",
            "stack": "stack-1",
            "data": projecoes,
            "fill": false,
          },
      ]},
      "options": {
        "scales": {
          "xAxes": [{
            "stacked": true,
            "gridLines": { "display": false }
          }],
          "yAxes":[{
            "stacked": true,
            "gridLines": { "display": false },
            "ticks": {
              "beginAtZero":true,
              callback: function(value, index, values) {
                return ''
              }
            }
          }]
        },
        title: {
          display: true,
          text: title
        },
        legend: {
          display: false,
          position: 'bottom',
        },
        plugins: {
          labels: {
            render: () => ''
          },
        },
      }
    });
  }
}
