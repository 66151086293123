import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.placeholder = "MM/AAAA"
    new Cleave(this.element, {
      date: true,
      datePattern: ["m", "Y"]
    })
  }

}
