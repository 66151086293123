import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $.fn.select2.amd.require([
      'select2/selection/single',
      'select2/selection/placeholder',
      'select2/selection/allowClear',
      'select2/dropdown',
      'select2/dropdown/search',
      'select2/dropdown/attachBody',
      'select2/utils'
    ], (SingleSelection, Placeholder, AllowClear, Dropdown, DropdownSearch, AttachBody, Utils) => {
      let selectionAdapter = Utils.Decorate(
        SingleSelection,
        Placeholder
      );

      selectionAdapter = Utils.Decorate(
        selectionAdapter,
        AllowClear
      );

      let dropdownAdapter = Utils.Decorate(
        Utils.Decorate(
          Dropdown,
          DropdownSearch
        ),
        AttachBody
      );

      this.create(selectionAdapter, dropdownAdapter)
    });
  }

  create(selectionAdapter, dropdownAdapter){
    let configSelect2 = {
      language: 'pt-BR',
      placeholder: this.getOrDefault('placeholder', ''),
      allowClear: this.getOrDefault('allow-clear', false),
      closeOnSelect: this.getOrDefault('close-on-select', true),
      multiple: this.getOrDefault('multiple', false),
      maximumInputLength: this.getOrDefault('maximum-input-length', 0),
      minimumInputLength: this.getOrDefault('minimum-input-length', 0),
      minimumResultsForSearch: this.getOrDefault('minimum-results-for-search', 0),
      maximumSelectionLength: this.getOrDefault('maximum-selection-length', 0)
    }

    if (this.data.get('multiple')) {
      configSelect2 = Object.assign(configSelect2, {
        selectionAdapter,
        dropdownAdapter,
        templateResult: function (data) {
          if (!data.id) { return data.text; }
          let $res = $('<div></div>');

          $res.text(data.text);
          $res.addClass('wrap');

          return $res;
        },
        templateSelection: (data) => {
          let values = $(this.element).val()
          let selected = (values || []).length;
          selected = values.includes("") ? selected - 1 : selected
          if (!(selected > 0)) { return data.text; }

          let total = $('option', $(this.element)).length;
          return `${selected} de ${total}`;
        }
      })
    }

    $(this.element).select2(configSelect2)
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

  getOrDefault(name, defaultValue) {
    if (this.data.get(name) == undefined) {
      return defaultValue
    } else {
      return this.data.get(name)
    }
  }
}
