// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Core libraries
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// App libraries
require("bootstrap")
require("jquery.nicescroll")
require("cleave.js")
require("cleave.js/dist/addons/cleave-phone.br.js")
require("chart.js")
require("select2/dist/js/select2.full")
require("select2/dist/js/i18n/pt-BR")

window.iziToast = require("izitoast")

// Stisla
require("vendors/stisla/stisla")
require("vendors/stisla/scripts")

// Application
require("app").start()

import "controllers"
