import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const target = document.getElementById(this.element.dataset.target);
    this.element.addEventListener('click', (e) => {
      e.preventDefault()
      this.requestFullscreen(target || document.documentElement)
    }, false)
  }

  requestFullscreen(element) {
    element.style.backgroundColor = "white"
    if (element.requestFullscreen) {
      element.requestFullscreen()
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen()
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen()
    }
  }

}
