import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(this.element).select2({
      language: 'pt-BR',
      dropdownAutoWidth: true,
      placeholder: this.getOrDefault('placeholder', ''),
      allowClear: this.getOrDefault('allow-clear', false),
      closeOnSelect: this.getOrDefault('close-on-select', true),
      multiple: this.getOrDefault('multiple', false),
      maximumInputLength: this.getOrDefault('maximum-input-length', 0),
      minimumInputLength: this.getOrDefault('minimum-input-length', 0),
      minimumResultsForSearch: this.getOrDefault('minimum-results-for-search', 0),
      maximumSelectionLength: this.getOrDefault('maximum-selection-length', 0)
    })
    $(this.element).on('change', function(){
      window.location = $(this).val()
    });
  }

  disconnect() {
    $(this.element).select2("destroy")
  }

  getOrDefault(name, defaultValue) {
    if (this.data.get(name) == undefined) {
      return defaultValue
    } else {
      return this.data.get(name)
    }
  }
}
