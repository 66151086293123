import { Controller } from "stimulus"
import Chart from 'chart.js'
import 'chartjs-plugin-labels';

const objetivocolor = alpha => `rgba(54, 162, 235, ${alpha || 1})`
const realizadocolor = alpha => `rgba(75, 192, 192, ${alpha || 1})`

const abbreviateNumber = (n) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
}
const abbreviateName = (value) => value.split(' ').slice(0, 2).join(' ')

const pluginDrawValue = {
  id: 'drawValue',
  options: [],
  afterDraw: function (chart) {
    if (!chart.options.plugins.drawValue.enable) return
    let chartInstance = chart
    let ctx = chartInstance.ctx

    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily)
    ctx.textAlign = 'top'
    ctx.textBaseline = 'top'

    chart.data.datasets.forEach(function (dataset, i) {
      let meta = chartInstance.controller.getDatasetMeta(i)
      meta.data.forEach(function (bar, index) {
        let data = ' '+abbreviateNumber(dataset.data[index])
        ctx.fillText(data, bar._model.x, bar._model.y - 5)
      })
    })
  }
}

const SUMMARY_SIZE = 5
const OPTIONS_CHART_HORIZONTALBAR = {
  scales: {
    yAxes:[{
      gridLines: { "display": false },
      ticks: {
        callback: abbreviateName
      }
    }],
    xAxes:[{
      gridLines: { "display": false },
      ticks: {
        beginAtZero: true,
        callback: abbreviateNumber
      }
    }]
  },
  responsive: true,
  legend: {
    display: false,
    position: 'left',
  },
  plugins: {
    drawValue: {
      enable: true,
    }
  }
}
const OPTIONS_CHART_PIE = {
  OPTIONS_CHART_HORIZONTALBAR, ...
  {
    legend: {
      position: 'left',
      display: !true,
    },
    plugins: {
      drawValue: {
        enable: false
      },
      labels: [
        {
          render: (value) => abbreviateName(value.label),
          position: 'outside'
        },
        {
          render: 'percentage',
          fontStyle: 'bold',
          fontColor: '#FFF',
        }
      ]
    }
  }
}
const OPTIONS_CHART_HISTORICO = {
  elements: {
    line: {
      tension: 0
    }
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [{
      stacked: true,
      gridLines: { "display": false }
    }],
    yAxes:[{
      stacked: true,
      gridLines: { "display": false },
      ticks: {
        beginAtZero: true,
        callback: function() {
          return ''
        }
      }
    }]
  },
  plugins: {
    labels: {
      render: () => ''
    },
  }
}
const fullscreenChartOptionXaxes = (display) => [{
  gridLines: { display },
  ticks: {
    beginAtZero: true,
    callback: abbreviateNumber
  }
}]

export default class extends Controller {

  connect() {
    document.periodos = JSON.parse(this.data.get('periodos'))
    document.tabela = {}
    let rows = document.querySelectorAll('#tabela-vendas tbody tr')
    rows.forEach(function(row){
      document.tabela[row.id] = JSON.parse(row.dataset.historico)
      document.tabela[row.id].total_vendas = JSON.parse(row.dataset.total_vendas)
      document.tabela[row.id].qtde_vendas = JSON.parse(row.dataset.qtde_vendas)
      document.tabela[row.id].nome = row.dataset.nome
      document.tabela[row.id].selected = false
    })

    this.plot()
    this.bindEvents()
  }

  selectAllTabelaVendas(e){
    e.preventDefault()
    let rows = document.querySelectorAll('#tabela-vendas tbody tr')
    rows.forEach(function(row){
      document.tabela[row.id].selected = true
      document.getElementById(row.id).classList.add('table-active', row.selected)
    })

    this.update()
  }

  deselectAllTabelaVendas(e){
    e.preventDefault()
    let rows = document.querySelectorAll('#tabela-vendas tbody tr')
    rows.forEach(function(row){
      document.tabela[row.id].selected = false
      document.getElementById(row.id).classList.remove('table-active', row.selected)
    })

    this.update()
  }

  invertAllTabelaVendas(e){
    e.preventDefault()
    let rows = document.querySelectorAll('#tabela-vendas tbody tr')
    rows.forEach(function(row){
      document.tabela[row.id].selected = !document.tabela[row.id].selected
      document.getElementById(row.id).classList.toggle('table-active', row.selected)
    })

    this.update()
  }

  bindEvents(){
    let btnToggleChart1 = document.getElementById('btnToggleChart1');
    let btnToggleChart2 = document.getElementById('btnToggleChart2');
    let btnToggleChart3 = document.getElementById('btnToggleChart3');

    btnToggleChart1.addEventListener('click', (e) => {
      e.preventDefault()
      this.changeHistorico(btnToggleChart1, this.chartHistorico)
    }, false)

    btnToggleChart2.addEventListener('click', (e) => {
      e.preventDefault()
      this.changeHorizontalBar(btnToggleChart2, this.chartContribuicao)
    }, false)

    btnToggleChart3.addEventListener('click', (e) => {
      e.preventDefault()
      this.changeHorizontalBar(btnToggleChart3, this.chartProdutos)
    }, false)

    document.addEventListener("fullscreenchange", () => {
      let isFullScreenMode =  document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
      this.chartContribuicao.options.scales.xAxes = fullscreenChartOptionXaxes(isFullScreenMode)
      this.chartContribuicao.options.scales.xAxes = fullscreenChartOptionXaxes(isFullScreenMode)
      this.chartContribuicao.options.plugins.drawValue.enable = !isFullScreenMode,
      this.chartProdutos.options.plugins.drawValue.enable = !isFullScreenMode,
      this.chartProdutos.options.scales.xAxes = fullscreenChartOptionXaxes(isFullScreenMode)
      this.update(isFullScreenMode)
    });
  }

  plot(){
    this.labels = []
    this.contribuicoes = []
    this.produtos = []
    this.periodos = document.periodos
    this.metas = this.hashFor(document.periodos)
    this.vendas = this.hashFor(document.periodos)
    this.metasSummarize = this.hashFor(document.periodos)
    this.vendasSummarize = this.hashFor(document.periodos)

    let selections = 0
    Object.keys(document.tabela).forEach((key) => {
      let row = document.tabela[key]
      this.labels.push(row.nome)
      this.contribuicoes.push(row.total_vendas)
      this.produtos.push(row.qtde_vendas)

      this.periodos.forEach((periodo) => {
        this.metas[periodo] += row.metas[periodo]
        this.vendas[periodo] += row.vendas[periodo]
        if (selections < SUMMARY_SIZE){
          this.metasSummarize[periodo] += row.metas[periodo]
          this.vendasSummarize[periodo] += row.vendas[periodo]
        }

      })
      selections++
    })

    // plot
    this.chartHistorico = this.plotHistorico(
      this.periodos,
      this.extractFrom(this.metas),
      this.extractFrom(this.vendas),
      this.hashFor(this.periodos)
    )
    this.chartContribuicao = this.plotHorizontalBar(
      'contribuicao',
      'Contribuições',
      this.summarize(this.labels),
      this.summarize(this.contribuicoes)
    )
    this.chartProdutos = this.plotHorizontalBar(
      'produtos',
      'Produtos',
      this.summarize(this.labels),
      this.summarize(this.produtos)
    )

    this.update()
  }

  summarize(collection){
    return collection.slice(0, SUMMARY_SIZE)
  }

  toggle(e) {
    e.preventDefault()
    let row = document.tabela[e.target.dataset.row]
    row.selected = !row.selected
    document.getElementById(e.target.dataset.row).classList.toggle('table-active', row.selected)

    this.update()
  }

  update(showAll) {
    this.labelsSelected = []
    this.contribuicoesSelected = []
    this.produtosSelected = []
    this.metasSelected = this.hashFor(this.periodos)
    this.vendasSelected = this.hashFor(this.periodos)

    Object.keys(document.tabela).forEach((key) => {
      let row = document.tabela[key]
      if (row.selected) {
        this.labelsSelected.push(row.nome)
        this.contribuicoesSelected.push(row.total_vendas)
        this.produtosSelected.push(row.qtde_vendas)

        this.periodos.forEach((periodo) => {
          this.metasSelected[periodo] += row.metas[periodo]
          this.vendasSelected[periodo] += row.vendas[periodo]
        })
      }
    })

    let hasItemSelected = (this.labelsSelected.length > 0)
    if(showAll && !hasItemSelected){
      let hasValue = 0
      this.labels.forEach((_, index) => {
        if (this.contribuicoes[index] > 0 || this.produtos[index] > 0) {
          hasValue = index + 1
        }
      })
      this.updateHorizontalBar(this.chartContribuicao, this.labels.slice(0, hasValue), this.contribuicoes.slice(0, hasValue))
      this.updateHorizontalBar(this.chartProdutos, this.labels.slice(0, hasValue), this.produtos.slice(0, hasValue))
      this.updateHistorico(
        this.periodos,
        this.extractFrom(this.metas),
        this.extractFrom(this.vendas),
        this.hashFor(this.periodos)
      )
      return
    }

    if (!hasItemSelected){
      this.labelsSelected = this.summarize(this.labels)
      this.contribuicoesSelected = this.summarize(this.contribuicoes)
      this.produtosSelected = this.summarize(this.produtos)
      this.metasSelected = this.metasSummarize
      this.vendasSelected = this.vendasSummarize
    }

    this.updateHorizontalBar(this.chartContribuicao, this.labelsSelected, this.contribuicoesSelected)
    this.updateHorizontalBar(this.chartProdutos, this.labelsSelected, this.produtosSelected)
    this.updateHistorico(
      this.periodos,
      this.extractFrom(this.metasSelected),
      this.extractFrom(this.vendasSelected),
      this.hashFor(this.periodos)
    )
  }

  updateHistorico(labels, metas, vendas, projecoes) {
    this.chartHistorico.data.labels = labels
    this.chartHistorico.data.datasets[0].data = metas
    this.chartHistorico.data.datasets[1].data = vendas
    this.chartHistorico.data.datasets[2].data = projecoes
    this.chartHistorico.update();
  }

  plotHistorico(labels, metas, vendas, projecoes) {
    let element = document.getElementById('historico').getContext('2d')
    return new Chart(element, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Objetivo",
            stack: "stack-0",
            data: metas,
            fill: false,
            backgroundColor: metas.map(function(meta){
              return objetivocolor()
            })
          },
          {
            label:"Realizado",
            stack: "stack-1",
            data: vendas,
            fill: false,
            backgroundColor: vendas.map(function(venda) {
              return realizadocolor()
            })
          },
          {
            label: "Adicional Projeção",
            stack: "stack-1",
            data: projecoes,
            fill: false,
          },
        ]},
      options: OPTIONS_CHART_HISTORICO
    });
  }

  hashFor(list) {
    return list.reduce(function(hash, item) {
      hash[item] = 0
      return hash
    }, {})
  }

  extractFrom(hash) {
    return document.periodos.map(function(periodo){
      return hash[periodo]
    })
  }

  plotHorizontalBar(target, label, labels, datasets) {
    let element = document.getElementById(target).getContext('2d')
    return new Chart(element, {
      type: "horizontalBar",
      data: {
        labels,
        datasets: [
          {
            label,
            data: datasets,
            backgroundColor: this.colorize(datasets)
          },
        ]},
      options: OPTIONS_CHART_HORIZONTALBAR,
      plugins: [pluginDrawValue]
    });
  }

  colorize(datasets){
    let colors = []
    datasets.forEach(function(_, index){
      colors.push(realizadocolor((index)/datasets.length + .3))
    })
    return colors.reverse()
  }

  updateHorizontalBar(chart, labels, datasets) {
    let arrayOfObj = labels.map(function(d, i) { return { label: d, data: (datasets[i] || 0)}})
    let sortedArrayOfObj = arrayOfObj.sort((a, b) => (a.data < b.data) ? 1 : -1)
    let newLabel = [];
    let newData = [];

    sortedArrayOfObj.forEach(function(d){
      newLabel.push(d.label);
      newData.push(d.data);
    });

    chart.data.datasets = [{
      data: newData,
      backgroundColor: this.colorize(datasets)
    }]

    chart.data.labels = newLabel
    chart.update();
  }

  changeHorizontalBar(btn, chart){
    let icon = btn.querySelector('i')
    icon.classList.toggle("fa-chart-pie")
    icon.classList.toggle("fa-chart-bar")

    chart.config.type = (chart.config.type === 'pie') ? 'horizontalBar' : 'pie'
    chart.options = (chart.config.type === 'pie') ? OPTIONS_CHART_PIE : OPTIONS_CHART_HORIZONTALBAR
    chart.update()
  }

  changeHistorico(btn, chart){
    let icon = btn.querySelector('i')
    icon.classList.toggle("fa-chart-line")
    icon.classList.toggle("fa-chart-bar")

    chart.config.type = (chart.config.type === 'bar') ? 'line' : 'bar'
    chart.update()
  }
}
