import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.plotGauge(document.getElementById('gauge'))
  }

  plotGauge(element) {
    var angle = ((parseInt(element.dataset.targetAtual) * 180) / 100) - 180
    new Chart(element, {
      type: 'doughnut',
      data: {
        datasets: [{
          data: [ 50, 25, 25, ],
          backgroundColor: [
            window.chartColors.red,
            window.chartColors.yellow,
            window.chartColors.green,
          ],
          label: 'Dataset 1'
        }],
        labels: [ 'Red', 'Yellow', 'Green', ]
      },
      options: {
        responsive: true,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        legend: false,
        title: {
          display: true,
          text: 'Target atual'
        },
        animation: {
          animateScale: true,
          animateRotate: true,
          onComplete: function () {
            drawNeedle(50, angle * Math.PI / 180);
          }
        },
      }
    });
  }

}

function drawNeedle(radius, radianAngle) {
  var canvas = document.getElementById('gauge');
  var ctx = canvas.getContext('2d');
  var cw = canvas.offsetWidth;
  var ch = canvas.offsetHeight + 50;
  var cx = cw / 2;
  var cy = ch - (ch / 4);
  ctx.translate(cx, cy);
  ctx.rotate(radianAngle);
  ctx.beginPath();
  ctx.moveTo(0, -5);
  ctx.lineTo(radius, 0);
  ctx.lineTo(0, 5);
  ctx.fillStyle = 'rgba(76, 76, 76, 0.8)';
  ctx.fill();
  ctx.rotate(-radianAngle);
  ctx.translate(-cx, -cy);
  ctx.beginPath();
  ctx.arc(cx, cy, 7, 0, Math.PI * 2);
  ctx.fill();
}
