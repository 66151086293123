import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.placeholder = "000.000.000-00"
    new Cleave(this.element, {
      delimiters: ['.', '.', '-'],
      blocks: [3, 3, 3, 2],
    })
  }

}
