import { Controller } from "stimulus"
import datatable from 'datatables.net'
require('datatables.net-bs4')
require('datatables.net-buttons')
require('datatables.net-fixedheader')
require('datatables.net-fixedheader-bs4')

export default class extends Controller {

  connect() {
    $.extend($.fn.dataTableExt.oSort, {
      "numeric-br-pre": function(a) {
        var x = (a == "-") ? 0 : parseFloat(a.replace('.','').replace(',','.'))
        return parseFloat(x);
      },
      "numeric-br-asc": function(a, b) {
        return ((a < b) ? -1 : ((a > b) ? 1 : 0));
      },
      "numeric-br-desc": function(a, b) {
        return ((a < b) ? 1 : ((a > b) ? -1 : 0));
      }
    });
    $(this.element).DataTable({
      dom: "<'row'<'col-sm-12'tr>>" +
           "<'row pb-4'<'col-sm-12 col-md-6 pl-4'p><'col-sm-12 col-md-6'<'float-right text-muted text-sm pr-4'i>>>",
      retrieve: true,
      language: {
        sEmptyTable: "Nenhum registro encontrado",
        sInfo: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        sInfoEmpty: "Mostrando 0 até 0 de 0 registros",
        sInfoFiltered: "(Filtrados de _MAX_ registros)",
        sInfoPostFix: "",
        sInfoThousands: ".",
        sLengthMenu: "_MENU_ resultados por página",
        sLoadingRecords: "Carregando...",
        sProcessing: "Processando...",
        sZeroRecords: "Nenhum registro encontrado",
        sSearch: "Pesquisar",
        oAria: {
          sSortAscending: ": Ordenar colunas de forma ascendente",
          sSortDescending: ": Ordenar colunas de forma descendente"
        },
      }
    })
  }

}
