import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.placeholder = "00.000.000/0000-00"
    new Cleave(this.element, {
      delimiters: ['.', '.', '/', '-'],
      blocks: [2, 3, 3, 4, 2],
    })
  }

}
