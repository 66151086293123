import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    this.element.placeholder = "00 0000 0000"
    new Cleave(this.element, {
      phone: true,
      phoneRegionCode: 'BR'
    })
  }

}
